<template>
  <section class="junctions" v-if="allSystems.length">
    <div class="container">
      <div class="junctions__title major-title">
        {{ $t('message.junctions.title') }}
      </div>
    </div>
    <Junctions-nav :junctions="sectorFromState.junctions" :isShowDelete="true" />
    <Products-filter
      :products="filteredJunctionsBySystem"
      :normalizedCategories="normalizedCategories"
      :categories="junctionsCategories"
      @returnFilteredProducts="showFilteredProducts"
    />
    <div class="container">
      <Products-view
        :products="productsToProductView"
        placement="junction"
        @productSelected="addJunctionToSector"
        @showGroupModal="showGroupModal"
        @createCustom="createCustomJunction"
      >
        <template #image>
          <img src="@/assets/img/icons/junction--create.svg" alt="create" />
        </template>
        <i18n path="message.junctions.create" class="create-btn__text">
          <template #break>
            <br />
          </template>
        </i18n>
      </Products-view>
      <Group-modal :isModalOpen="isModalOpen" :isShowClose="true" @close="close">
        <template #body>
          <Junctions-group :group="group" @itemSelected="itemSelected" />
        </template>
      </Group-modal>
      <div class="step-buttons">
        <button @click="goToPrevStep" class="btn btn-back">
          <img src="@/assets/img/icons/step--back.svg" alt="←" />
          <span class="btn__text">{{ getPrevStepText() }}</span>
        </button>
      </div>
    </div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </section>
</template>

<script>
import GroupModal from '@/components/elements/Modals/Modal'
import ProductsView from '@/components/smart/products/Products-view'
import ProductsFilter from '@/components/smart/products/Products-filter'
import JunctionsNav from '@/components/elements/Junction/JunctionsNav'
import JunctionsGroup from '@/components/smart/Junction/JunctionsGroup'
import { mapMutations, mapState, mapActions } from 'vuex'
import { isSomeWedgeIsEnabled } from '@/utils/customFunctions'
import { getItem } from '@/api'
import { stepNumbers } from '@/utils/stepNumbers'

export default {
  data() {
    return {
      filteredProducts: [],
      isModalOpen: false,
      isLoading: false,
      isFiltered: false,
      group: {}
    }
  },
  components: {
    ProductsView,
    ProductsFilter,
    JunctionsNav,
    GroupModal,
    JunctionsGroup
  },
  methods: {
    ...mapActions({
      updateStep: 'updateStep'
    }),
    ...mapMutations({
      ADD_JUNCTION_TO_SECTOR: 'ADD_JUNCTION_TO_SECTOR',
      CREATE_CUSTOM_JUNCTION: 'CREATE_CUSTOM_JUNCTION'
    }),
    addJunctionToSector(id) {
      this.isLoading = true
      const isReplaceJunction =
        !!this.$route.params.isReplace && !!this.sectorFromState.junctions.length
      isReplaceJunction ? this.replaceJunction(id, null) : this.addJunction(id, null)
    },
    replaceJunction(id, groupId) {
      const { replacementJunction } = this.$route.params
      const isReplacementJunctionExist = this.sectorJunctions.some(
        j => j.uid === replacementJunction
      )
      if (isReplacementJunctionExist) {
        const newJunctionIndex = this.sectorJunctions.findIndex(j => j.uid === replacementJunction)
        getItem(this.$i18n.locale, 'junction', id).then(response => {
          this.ADD_JUNCTION_TO_SECTOR({
            sectorIndex: this.sectorIndex,
            junctionId: id,
            junctionType: this.getJunctionType(id, groupId),
            layers: response.data.layers,
            groupId: groupId,
            replacementJunctionUid: replacementJunction
          })
          this.isLoading = false
          this.$router.push(
            `/${this.$i18n.locale}/system/${this.sectorIndex}/junction/${newJunctionIndex}`
          )
        })
      } else {
        this.addJunction(id, groupId)
      }
    },
    addJunction(id, groupId) {
      getItem(this.$i18n.locale, 'junction', id).then(response => {
        this.ADD_JUNCTION_TO_SECTOR({
          sectorIndex: this.sectorIndex,
          junctionId: id,
          junctionType: this.getJunctionType(id, groupId),
          layers: response.data.layers,
          groupId: groupId,
          replacementJunctionUid: null
        })
        this.isLoading = false
        this.$router.push(
          `/${this.$i18n.locale}/system/${this.sectorIndex}/junction/${this.junctionIndex}`
        )
      })
    },
    itemSelected(itemId, groupId) {
      this.isModalOpen = false
      this.isLoading = true
      const isReplaceJunction =
        !!this.$route.params.isReplace && !!this.sectorFromState.junctions.length
      isReplaceJunction ? this.replaceJunction(itemId, groupId) : this.addJunction(itemId, groupId)
    },
    getJunctionType(id, groupId) {
      return groupId
        ? this.allJunctions.find(p => p.id === groupId).items.find(p => p.id === id).type
        : this.allJunctions.find(p => p.id === id).type
    },
    createCustomJunction() {
      const isReplaceJunction =
        !!this.$route.params.isReplace && !!this.sectorFromState.junctions.length
      isReplaceJunction ? this.replaceExistJunctionToCustom() : this.addNewCustomJunction()
    },
    replaceExistJunctionToCustom() {
      const { replacementJunction } = this.$route.params
      const isReplacementJunctionExist = this.sectorJunctions.some(
        j => j.uid === replacementJunction
      )
      if (isReplacementJunctionExist) {
        const newJunctionIndex = this.sectorJunctions.findIndex(j => j.uid === replacementJunction)
        this.CREATE_CUSTOM_JUNCTION({
          sectorIndex: this.sectorIndex,
          replacementJunctionUid: replacementJunction
        })
        this.$router.push(
          `/${this.$i18n.locale}/system/${this.sectorIndex}/junction/${newJunctionIndex}`
        )
      } else {
        this.addNewCustomJunction()
      }
    },
    addNewCustomJunction() {
      this.CREATE_CUSTOM_JUNCTION({
        sectorIndex: this.sectorIndex,
        replacementJunctionUid: null
      })
      this.$router.push(
        `/${this.$i18n.locale}/system/${this.sectorIndex}/junction/${this.junctionIndex}`
      )
    },

    showFilteredProducts(products) {
      this.isFiltered = true
      this.filteredProducts = products
    },
    showGroupModal(group) {
      this.group = group
      this.isModalOpen = true
    },
    close() {
      this.isModalOpen = false
    },
    isJunctionSingle(junction) {
      return Object.prototype.hasOwnProperty.call(junction, 'groupName')
    },

    goToPrevStep() {
      if (this.isSomeWedgeIsEnabled) {
        this.$router.push(`/${this.$i18n.locale}/wedge/${this.sectorIndex}`)
      } else {
        this.$router.push(`/${this.$i18n.locale}/system/${this.sectorIndex}`)
      }
    },
    getPrevStepText() {
      return this.isSomeWedgeIsEnabled
        ? `${this.$i18n.t('message.stepButtons.setUpWedge')}`
        : `${this.$i18n.t('message.stepButtons.setUpSystem')}`
    }
  },
  mounted() {
    if (this.allSystems.length === 0) {
      this.$router.push(`/${this.$i18n.locale}/`)
    }
    this.updateStep(stepNumbers.junctions)
  },
  computed: {
    ...mapState({
      allJunctions: state => state.junctions,
      allSystems: state => state.systems,
      junctionsCategories: state => state.junctionsCategories,
      responseJunctionLayers: state => state.junctionLayers
    }),
    normalizedCategories() {
      return Object.fromEntries(this.junctionsCategories.map(c => [c.id, []]))
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    sectorJunctions() {
      return this.sectorFromState.junctions
    },
    junctionIndex() {
      return this.sectorJunctions.length - 1
    },
    systemBaseType() {
      return this.sectorFromState.layers.find(layer => layer.isBaseLayer).items[0].baseTypeId
    },
    isFilterByInsulationNeed() {
      const insulationLayers = this.sectorFromState.layers.filter(
        layer => layer.insulation && layer.isEnabled
      )
      const validInsulationLayers = insulationLayers.filter(layer =>
        layer.items.some(item => item.insulation)
      )
      return validInsulationLayers.some(layer =>
        layer.items.some(item => item.material.isolationType !== '')
      )
    },
    insulationLayerForFilter() {
      const layer = this.sectorFromState.layers
        .filter(layer => {
          return (
            layer.insulation &&
            layer.items.some(item => item.insulation && item.material.isolationType !== '')
          )
        })
        .pop()
      return layer.items.filter(item => item.material.isolationType !== '').pop()
    },
    filteredJunctionsBySystem() {
      return this.sectorFromState.isCustom
        ? this.filteredJunctionsCustomSystem
        : this.filteredJunctionsDefaultSystem
    },
    filteredJunctionsCustomSystem() {
      if (this.isFilterByInsulationNeed) {
        const insulationId = this.insulationLayerForFilter.material.isolationType
        return this.allJunctions.filter(junction => {
          return (
            junction.baseType.includes(this.systemBaseType) &&
            junction.isolationType.includes(insulationId)
          )
          // return !this.isJunctionSingle(junction)
          //   ? junction.baseType === this.systemBaseType && junction.isolationType === insulationId
          //   : junction.baseType.includes(this.systemBaseType) &&
          //       junction.isolationType.includes(insulationId)
        })
      } else {
        return []
        // return this.allJunctions.filter(junction => {
        //   return !this.isJunctionSingle(junction)
        //     ? junction.baseType === this.systemBaseType
        //     : junction.baseType.includes(this.systemBaseType)
        // })
      }
    },
    filteredJunctionsDefaultSystem() {
      return this.allJunctions.filter(j => j.systems_ref.includes(this.sectorFromState.system))
    },
    productsToProductView() {
      return this.isFiltered ? this.filteredProducts : this.filteredJunctionsBySystem
      // return this.filteredProducts.length === 0 ? this.allJunctions : this.filteredProducts;
    },
    isSomeWedgeIsEnabled() {
      return isSomeWedgeIsEnabled(this.sectorFromState.layers)
    }
  }
}
</script>

<style scoped lang="sass">
.junctions
  &__filter
    height: rem(64)
    background: #fff
</style>
